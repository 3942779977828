<template>
<div>
    <div class="card">
        <!-- Logo -->
        <div class="card-header pt-4 pb-4 text-center ">
            <span><img src="@/assets/images/new_logo.svg" alt="" height="52"></span>
        </div>

        <div class="card-body text-center p-4">  
            <p>This website currently works only via approve from an administrator.</p>
            <p>Please write a message to <b>rdrctly@spartak.io</b></p>

            <p class="mt-4">Please update the page when you'll be approved</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    beforeMount() {
        if(this.$store.getters['auth/approved'])
            this.$router.push('/')
    }
}
</script>